import styles from "./style.module.scss";
import Message, {MessageTyping} from "./components/Message/index";
import {API_URL} from "../../config";
import ChatBox from "./components/ChatBox/index";
import {useCallback, useEffect, useMemo, useRef} from "react";
import AssistantError from "../AssistantError/index";
import ToolCall from "../ToolCall/index";
import {useChat} from "../../contexts/ChatContext";
import {useTranslation} from 'react-i18next';

const Chat = () => {

    const {isCompletionPending, messages, streamedMessage, submit} = useChat();

    const containerRef = useRef(null);

    const showTyping = useMemo(() => isCompletionPending && !streamedMessage, [isCompletionPending, streamedMessage]);

    const messagesWithStreamedMessage = useMemo(() => {
        if (!streamedMessage) {
            return messages;
        }

        const newMessage: any = {type: 'message', data: { role: 'assistant', message: streamedMessage }};
        return [...messages, newMessage];
    }, [messages, streamedMessage]);

    const renderComponent = useCallback((component, index?) => {
        switch(component.type) {
            // case 'preview_order':
            //     return <PreviewOrder key={index} {...component.data} />
            // case 'lead':
            //     return <Lead key={index} {...component.data} />
            // case 'leads':
            //     return <Leads key={index} {...component.data} />
            case 'openai_error':
                return <AssistantError key={index} label={"Vyskytla sa chyba u OpenAI."} {...component.data} />
            case 'server_error':
                return <AssistantError key={index} label={"Vyskytla sa chyba na servery."} {...component.data} />
            // case 'assistant_error':
            //     return <AssistantError key={index} label={"Asistent narazil na chybu."} {...component.data} />
            case 'tool_call':
                return <ToolCall key={index} {...component.data} />
            default:
                return null;
        }
    }, []);

    useEffect(() => {
        // window.scrollTo(0, document.body.scrollHeight); // TODO: edit to scroll to the bottom of the chat container
        // containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }, [messagesWithStreamedMessage, showTyping]);

    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "auto" });
    };

    useEffect(scrollToBottom, [messagesWithStreamedMessage, showTyping]);

    const { t } = useTranslation();

    return (
        <div className={styles.chatContainer} ref={containerRef}>
            <div className={styles.messagesContainer}>
                {
                    messagesWithStreamedMessage.map((message, index) => {
                        if (message.type === 'message') {
                            return <Message key={index} role={message.data.role} message={message.data.message}/>
                        } else if (message.type === 'component') {
                            return renderComponent(message.data, index);
                        } else if (message.type === 'image') {
                            return <img key={index} src={`${API_URL}/file/${message.data.filename}`}
                                        alt={message.data.filename}
                                        className={message.data.role === 'assistant' ? styles.imgUser : styles.imgAssistant}/>
                        }
                    })
                }
                {showTyping && <MessageTyping/>}
                <div ref={messagesEndRef} className={styles.scrollTarget} aria-hidden="true" aria-label={t('chat.messageContainer.scrollTarget')}>
                </div>
            </div>

            <div className={styles.footer}>
                {/*<div className={styles.separator}></div>*/}
                <div className="bg-gradient-to-b from-gray-900/0 to-gray-900 via-gray-900 via-65% h-6 w-full"></div>

                <ChatBox
                    isCompletionPending={isCompletionPending}
                    onSubmit={submit}
                />
            </div>
        </div>
    )
}

export default Chat;