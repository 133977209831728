import Dashboard from "../../layouts/Dashboard";
import useIndex from "../../hooks/UseIndex";
import {API_URL} from "../../config";
import {StockType} from "../../types/global";
import Pagination from "../../components/Pagination";
import React, {useMemo} from "react";
import useRefreshButtonNum from "../../hooks/Dashboard/UseRefreshButtonNum";
import {Status} from "../../types/enums";
import useEffectOnChange from "../../hooks/UseEffectOnValueChange";
import RefreshButton from "../../components/Dashboard/RefreshButton";
import {Spinner} from "flowbite-react";
import {useModelEvent} from "../../hooks/UseModelEvents";
import HeaderButton from "../../components/Dashboard/HeaderButton";
import {useNavigate} from "react-router-dom";
import {useTranslation} from 'react-i18next';
import StockTableRow from "./compoments/StockTableRow";


const StockIndex = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const limit = useMemo(() => 10, []);
    const endpoint = useMemo(() => `${API_URL}/stock`, []);

    const {data, status, fetchData, meta, setPage} = useIndex<StockType>(endpoint, limit, true);

    const isPending = useMemo(() => status === Status.Pending || status === Status.NotLoaded, [status]);

    const {refreshButtonNum, incrementRefreshButtonNum} = useRefreshButtonNum();

    useEffectOnChange(() => {
        fetchData();
    }, [refreshButtonNum]);

    const eventsToListen = useMemo(() => ["OnCreate", "OnDelete", "OnUpdate"], []);
    useModelEvent('Stock', eventsToListen, incrementRefreshButtonNum);
    useModelEvent('StockTransaction', eventsToListen, incrementRefreshButtonNum);
    useModelEvent('Order', eventsToListen, incrementRefreshButtonNum);

    const headerButton = useMemo(() => <HeaderButton label={t('stock.index.new')} onClick={() => navigate('/stock/create')} />, [navigate, t]);

    const productNamesTableHeader = useMemo(() => {
        if (data.length === 0) {
            return null;
        }

        return data[0].quantity.map((quantity, index) => (
            <th key={index} className={"font-normal py-1.5 px-2"}>{quantity.product_name}</th>
        ));
    }, [data]);

    return (<Dashboard heading={t('stock.index.title')} headerButton={headerButton}>

        <div className={"flex justify-end items-center gap-4"}>
            <div className={"flex gap-3"}>
                <RefreshButton isPending={isPending} onClick={incrementRefreshButtonNum}/>
            </div>
        </div>

        {data && <>
                <div className="overflow-x-auto w-full">
                    <table className="w-full mt-5 min-w-[600px]">
                        <thead className="text-sm sm:text-md text-left bg-gray-200 uppercase text-gray-500">
                        <tr className={"rounded-lg"}>
                            <th className={"font-normal py-1.5 px-2"}>{t('common.name')}</th>
                            {productNamesTableHeader}
                            <th className={"font-normal py-1.5 px-2"}></th>
                        </tr>
                        </thead>
                        <tbody className={"text-xs sm:text-sm"}>
                            {
                            data.length === 0 ?
                                <>
                                    {
                                        isPending ?
                                            <tr>
                                                <td colSpan={5} className={"text-center py-6"}><Spinner size="lg" className={"mx-auto"}/>
                                                </td>
                                            </tr> :
                                            <tr>
                                                <td colSpan={5} className={"text-center text-gray-500 py-4"}>
                                                    {t('common.noResults')}
                                                </td>
                                            </tr>
                                    }
                                </> :
                                data.map((stock, index) => (
                                    <StockTableRow key={stock._id} stock={stock}/>
                                ))
                        }
                        </tbody>
                    </table>
                </div>
                <Pagination meta={meta} setPage={setPage}/>
            </>
        }
    </Dashboard>);
}

export default StockIndex;