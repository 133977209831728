import {InvalidRequestType} from "../types/global";

export const getInvalidFieldsFromResponse = (response: InvalidRequestType): string[] => {
    const invalidFields = new Set<string>();

    if (response?.error_type === 'invalid_request' && Array.isArray(response.data)) {
        for (const item of response.data) {
            if (Array.isArray(item.loc)) {
                for (const loc of item.loc) {
                    invalidFields.add(loc);
                }
            }
        }
    }

    return Array.from(invalidFields);
}

/**
 * Set invalid fields based on the response from the server
 * @param response
 * @param setInvalid
 * @param casts - key from response to key in the form
 */
export const setInvalidFieldsFromResponse = (response: InvalidRequestType, setInvalid: (invalid: (prev) => any) => void, casts: Record<string, string> = {}): void => {
    const invalidFields = getInvalidFieldsFromResponse(response);

    const camelToSnake = (str: string) => {
        return str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
    };

    setInvalid((prev) => {
        const updatedInvalid = {...prev};
        Object.keys(prev).forEach((key) => {
            const responseKey = casts[key] || camelToSnake(key);
            updatedInvalid[key] = invalidFields.includes(responseKey);
        });
        return updatedInvalid;
    });
}

export const convertEmptyStringToNullInObject = (values: Record<string, any>): Record<string, any> => {
    return Object.keys(values).reduce((acc, key) => {
        // trim values
        if (typeof values[key] === "string") {
            values[key] = values[key].trim();
        }

        return {
            ...acc,
            [key]: values[key] === "" ? null : values[key]
        }
    }, {});
}