import useIndex from "../../../hooks/UseIndex";
import {API_URL} from "../../../config";
import {StockAggregatedTransactionType, StockType} from "../../../types/global";
import Pagination from "../../../components/Pagination";
import React, {useMemo} from "react";
import {Status} from "../../../types/enums";
import {Spinner} from "flowbite-react";
import {useModelEvent} from "../../../hooks/UseModelEvents";
import {useNavigate} from "react-router-dom";
import {useTranslation} from 'react-i18next';
import StockTransactionTableRow from "./StockTransactionTableRow";


const StockTransactionsTable = ({stock}: { stock: StockType }) => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const limit = useMemo(() => 10, []);
    const endpoint = useMemo(() => `${API_URL}/stock/${stock._id}/transaction`, [stock]);

    const {data, status, fetchData, meta, setPage} = useIndex<StockAggregatedTransactionType>(endpoint, limit);

    const isPending = useMemo(() => status === Status.Pending || status === Status.NotLoaded, [status]);

    const eventsToListen = useMemo(() => ["OnCreate", "OnDelete", "OnUpdate"], []);
    useModelEvent('StockTransaction', eventsToListen, fetchData);
    useModelEvent('Order', eventsToListen, fetchData);

    return data ? <>
        <div className="overflow-x-auto w-full">
            <table className="w-full mt-5 min-w-[600px]">
                <thead className="text-sm sm:text-md text-left bg-gray-200 uppercase text-gray-500">
                <tr className={"rounded-lg"}>
                    <th className={"font-normal py-1.5 px-2"}>{t('stock.show.transactionsTable.product')}</th>
                    <th className={"font-normal py-1.5 px-2"}>{t('stock.show.transactionsTable.quantity')}</th>
                    <th className={"font-normal py-1.5 px-2"}>{t('stock.show.transactionsTable.description')}</th>
                    <th className={"font-normal py-1.5 px-2"}>{t('stock.show.transactionsTable.date')}</th>
                    <th className={"font-normal py-1.5 px-2"}></th>
                    <th className={"font-normal py-1.5 px-2"}></th>
                </tr>
                </thead>
                <tbody className={"text-xs sm:text-sm"}>
                {
                    data.length === 0 ?
                        <>
                            {
                                isPending ?
                                    <tr>
                                        <td colSpan={5} className={"text-center py-6"}><Spinner
                                            size="lg"
                                            className={"mx-auto"}/>
                                        </td>
                                    </tr> :
                                    <tr>
                                        <td colSpan={5} className={"text-center text-gray-500 py-4"}>
                                            {t('common.noResults')}
                                        </td>
                                    </tr>
                            }
                        </> :
                        data.map((transaction, index) => (
                            <StockTransactionTableRow key={`${(transaction.stock_transaction_id ?? transaction.order_id)}${transaction.product_id}${index}`} transaction={transaction}/>
                        ))
                }
                </tbody>
            </table>
        </div>
        <Pagination meta={meta} setPage={setPage}/>
    </> : <div className={"flex justify-center"}><Spinner size="lg" className={"mt-6 mx-auto"}/></div>
}

export default StockTransactionsTable;