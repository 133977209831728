import i18next from 'i18next';

export const field = (value) => {
    return value ? value : '–';
}

export const getSourceColor = (source) => {
    switch (source) {
        case 'direct':
            return 'blue';
        case 'eshop':
            return 'yellow';
        case 'pharmacy':
            return 'green';
        case 'distributor':
            return 'purple';
        default:
            return 'gray';
    }
}

export const getSourceText = (source) => {
    return i18next.t(`utils.orderSource.${source}`, { defaultValue: '–' });
}