import {Dialog, DialogPanel, DialogTitle} from "@headlessui/react";
import {XMarkIcon} from "@heroicons/react/20/solid";
import React, {useCallback, useState} from "react";
import {useTranslation} from "react-i18next";
import useForm from "../../../hooks/UseForm";
import FormButton from "../../../components/Form/FormButtom";
import {useAuth} from "../../../contexts/AuthContext";
import {convertEmptyStringToNullInObject, setInvalidFieldsFromResponse} from "../../../utils/ApiUtils";
import {API_URL} from "../../../config";
import ComboboxProduct from "../../../components/Filters/ComboboxProduct";
import {ProductType, StockType} from "../../../types/global";
import {usePopupMessage} from "../../../contexts/PopupMessageContext";
import ComboboxStock from "../../../components/Filters/ComboboxStock";

const TransferDialog = ({isOpen, onClose, stockId}) => {
    const {t} = useTranslation();
    const {authToken} = useAuth();
    const [isPending, setIsPending] = useState(false);
    const {values, handleChange, invalid, setInvalid, changeValue} = useForm({amount: null, description: "", product: null, stockTo: null});

    const {push: pushMessage} = usePopupMessage();

    const submit = useCallback(async (e) => {
        e?.preventDefault();

        const fieldValues = convertEmptyStringToNullInObject(values);
        const amount = parseInt(fieldValues.amount, 10);
        const isAmountValid = !isNaN(amount) && amount > 0;
        if (!isAmountValid) {
            setInvalid((prevState) => ({...prevState, amount: true}));
            return;
        }
        if (!values.product) {
            setInvalid((prevState) => ({...prevState, product: true}));
            return;
        }

        if (!values.stockTo) {
            setInvalid((prevState) => ({...prevState, stockTo: true}));
            return;
        }

        const payload = {quantity: amount, stock_to_id: values.stockTo._id, description: values.description, product_id: values.product?.product_id};

        setIsPending(true);
        const response = await fetch(`${API_URL}/stock/${stockId}/transfer`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            },
            body: JSON.stringify(payload)
        })

        const responseData = await response.json();

        if (response.ok) {
            setTimeout(() => {
                setIsPending(false);
                onClose();
            }, 200);
        } else if (response.status === 422) {
            setInvalidFieldsFromResponse(responseData, setInvalid);
            setIsPending(false);
        } else {
            console.error(responseData);
            pushMessage(t("common.errorMessage"));
            setIsPending(false);
        }

    }, [authToken, onClose, pushMessage, setInvalid, stockId, t, values]);

    const handleSelectProduct = useCallback((product?: ProductType) => {
        changeValue('product', product);
    }, [changeValue]);

    const handleChangeToStock = useCallback((stock: StockType | null) => {
        changeValue('stockTo', stock);
    }, [changeValue]);

    return (
        <Dialog open={isOpen} onClose={onClose} className="relative z-50">
            <div
                className="fixed inset-0 flex w-screen items-center justify-center p-4 bg-black bg-opacity-35 backdrop-blur-sm">
                <DialogPanel className="max-w-lg bg-white shadow-lg rounded-2xl mb-24">
                    <DialogTitle
                        className="font-bold border-b bg-gradient-to-b from-white to-gray-50 p-3 rounded-t-2xl flex justify-between items-center">
                        {t(`stock.show.transfer.title`)}
                        <XMarkIcon
                            className="w-5 h-5 text-gray-500 cursor-pointer hover:text-gray-400 transition-colors"
                            onClick={onClose}/>
                    </DialogTitle>
                    <div className={"p-5 flex flex-col gap-4 w-72"}>

                        <ComboboxProduct onChange={handleSelectProduct} isInvalid={invalid.product}/>

                        <input
                            name={"amount"}
                            type={"number"}
                            placeholder={t('stock.show.transactionsTable.quantity')}
                            onChange={handleChange}
                            value={values.amount}
                            className={`block w-full rounded-md border-0 py-1.5 ring-1 text-gray-900 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6
                                ${invalid.amount ? 'ring-red-400 animationShake' : 'ring-gray-300'}
                            `}
                            step={1}
                            min={1}
                            required
                            aria-label={t('stock.show.transactionsTable.quantity')}
                        />

                        <ComboboxStock onChange={handleChangeToStock} isInvalid={invalid.stockTo} />

                        <input
                            name={"description"}
                            type={"text"}
                            placeholder={t('stock.show.transactionsTable.description')}
                            onChange={handleChange}
                            value={values.description}
                            className={`block w-full rounded-md border-0 py-1.5 ring-1 text-gray-900 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6
                                ${invalid.description ? 'ring-red-400 animationShake' : 'ring-gray-300'}
                            `}
                            required
                            aria-label={t('stock.show.transactionsTable.description')}
                        />

                        <FormButton
                            onClick={submit}
                            label={t('stock.show.transfer.submit')}
                            isPending={isPending}
                        />

                    </div>
                </DialogPanel>
            </div>
        </Dialog>
    )
}

export default TransferDialog;