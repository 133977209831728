import {OrderAttachment} from "../../types/global";
import {TrashIcon} from "@heroicons/react/24/solid";
import React, {useCallback, useState} from "react";
import {ReactComponent as UploadIcon} from "../../icons/paperclip-vertical.svg";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {useAuth} from "../../contexts/AuthContext";
import {usePopupMessage} from "../../contexts/PopupMessageContext";
import {Spinner} from "flowbite-react";
import {API_URL} from "../../config";
import useFileDownload from "../../hooks/UseFileDownload";


const Attachment = ({attachment}: {attachment: OrderAttachment}) => {
    const {t} = useTranslation();
    const {order_id} = useParams<{ order_id: string }>();
    const {authToken} = useAuth();
    const {push: pushMessage} = usePopupMessage();
    const [isDeletePending, setIsDeletePending] = useState(false);

    const deleteResource = useCallback(async () => {
        setIsDeletePending(true);
        const response = await fetch(`${API_URL}/order/${order_id}/attachment/${attachment._id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${authToken}`,
                'Content-Type': 'application/json',
            }
        });

        if (!response.ok) {
            pushMessage(t("common.error"));
            setIsDeletePending(false);
        } else {
            setTimeout(() => {
                setIsDeletePending(false);
            }, 200);
        }

    }, [authToken, attachment._id, order_id, pushMessage, t]);

    const deleteAlert = useCallback(async () => {
        if (window.confirm(t("common.deleteConfirmation"))) {
            await deleteResource();
        }
    }, [deleteResource, t]);

    const {downloadFile, isPending: isDownloadPending} = useFileDownload({downloadLink: attachment.download_link, fileName: attachment.file_name});

    return (
        <div className={"flex gap-2 justify-between items-center w-full"}>
            <div role={"button"}
                 onClick={downloadFile}
               className={"flex gap-1 items-center text-blue-500 hover:!text-blue-600 no-underline"} rel="noreferrer">
                {isDownloadPending ? <Spinner size="sm" /> : <UploadIcon/>}
                {attachment.file_name}
            </div>
            { isDeletePending ? <Spinner size="sm" /> : <div
                onClick={deleteAlert}
                role="button"
                className="inner-flex items-center justify-center text-gray-500 hover:text-gray-800 transition-colors duration-200"
            >
                <TrashIcon className={"w-4 h-4"} aria-label={t("common.delete")}/>
            </div> }

        </div>
    )
}

export default Attachment;