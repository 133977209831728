import {useCallback, useMemo, useState} from "react";
import {isEqual} from "lodash";

const useForm = <T extends object>(defaultValues: T) => {

    const [isPending, setIsPending] = useState(false);
    const [values, setValues] = useState<T>(defaultValues);
    const [invalid, setInvalid] = useState<Record<keyof T, boolean>>(() => {
        const fields: Record<keyof T, boolean> = {} as Record<keyof T, boolean>;
        Object.keys(defaultValues).forEach((key) => {
            fields[key as keyof T] = false;
        });
        return fields;
    });

    const [initialValues, setInitialValuesInternal] = useState<T>(defaultValues);

    const setInitialValues = useCallback((newValues: T) => {
        setInitialValuesInternal(newValues);
        setValues(newValues);
    }, []);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value
        });
        setInvalid((prev) => ({ ...prev, [name]: false }));
    };

    const changeValue = useCallback((name: keyof T, value: any) => {
        setValues({
            ...values,
            [name]: value
        });
        setInvalid((prev) => ({ ...prev, [name]: false }));
    }, [values]);

    const resetInvalid = useCallback(() => {
        setInvalid((prev) => {
            const updatedInvalid = { ...prev };
            Object.keys(prev).forEach((key) => {
                updatedInvalid[key] = false;
            });
            return updatedInvalid;
        });
    }, []);

    const getChangedValues = useCallback(() => {
        const changedValues: Partial<T> = {};
        Object.keys(values).forEach((key) => {
            if (!isEqual(values[key as keyof T], initialValues[key as keyof T])) {
                changedValues[key as keyof T] = values[key as keyof T];
            }
        });
        return changedValues;
    }, [values, initialValues]);

    const setters = useMemo(() => {
        const keys = Object.keys(defaultValues);
        const setters: Record<string, (value: any) => void> = {};
        keys.forEach((key) => {
            setters[`set${key.charAt(0).toUpperCase()}${key.slice(1)}`] = (value: any) => {
                setValues((prevValues) => ({
                    ...prevValues,
                    [key]: typeof value === 'function' ? value(prevValues[key]) : value
                }));
                setInvalid((prev) => ({ ...prev, [key]: false }));
            };
        });
        return setters;
    }, [defaultValues]);

    return {
        values,
        handleChange,
        setValues,
        invalid,
        setInvalid,
        changeValue,
        resetInvalid,
        setInitialValues,
        getChangedValues,
        setters,
        isPending,
        setIsPending
    };
}

export default useForm;