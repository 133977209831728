import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import skTranslations from './locales/sk.json';
import csTranslations from './locales/cs.json';
import enTranslations from './locales/en.json';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            'sk-SK': { translation: skTranslations },
            'cs-CZ': { translation: csTranslations },
            'en-GB': { translation: enTranslations },
        },
        lng: 'en-GB', // Default language
        fallbackLng: 'en-GB', // Fallback language if translation is missing,
        supportedLngs: ['sk-SK', 'cs-CZ', 'en-GB'],
        detection: {
            order: ['navigator'],
            caches: [],
        },
        interpolation: {
            escapeValue: false, // React already safes from XSS
        },
    });

export default i18n;