import {Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions, Transition} from '@headlessui/react'
import {ChevronUpDownIcon} from '@heroicons/react/20/solid'
import {forwardRef, Fragment, useCallback, useImperativeHandle, useMemo, useState} from 'react'
import useIndex from "../../../hooks/UseIndex";
import {ProductType} from "../../../types/global";
import {API_URL} from "../../../config";
import {Status} from "../../../types/enums";
import useDebounce from "../../../hooks/UseDebounce";
import useForm from "../../../hooks/UseForm";
import {useDidUpdateEffect} from "../../../hooks/UseDidUpdateEffect";
import {Price} from "../../../utils/Price";
import {useTranslation} from 'react-i18next';

// Define the interface for the ref
export interface ComboboxProductRef {
    setSelectedValue: (value: ProductType | null) => void;
}

// Define the props interface
interface ComboboxProductProps {
    onChange: (product: ProductType | null) => void;
    isInvalid?: boolean;
}

const ComboboxProduct = forwardRef<ComboboxProductRef, ComboboxProductProps>(({ onChange, isInvalid }, ref) => {
    const { t } = useTranslation();
    const [selectedProduct, setSelectedProduct] = useState(null)
    const endpoint = useMemo(() => `${API_URL}/product`, [])
    const limit = useMemo(() => 100, [])
    const { data: people, status, fetchData } = useIndex<ProductType>(endpoint, limit)
    const {values, handleChange} = useForm({query: ""})

    useDebounce(useCallback(() => {
        fetchData({ search: values.query })
    }, [values.query]))

    const filteredProducts = useMemo(() => people ?
        people.filter((product) => product.name != null)
        : [], [people]);

    useDidUpdateEffect(() => {
        onChange(selectedProduct)
    }, [selectedProduct]);

    useImperativeHandle(ref, () => ({
        setSelectedValue: (value) => {
            setSelectedProduct(value);
        }
    }));

    return (
        <div className="min-w-52">
            <Combobox value={selectedProduct} onChange={setSelectedProduct}>
                <div className="relative">
                    <div className={`relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left border border-gray-300 focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500
                    ${isInvalid ? 'ring-1 ring-red-400 animationShake' : ''}
                    `}>
                        <ComboboxInput
                            className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0 placeholder:text-gray-400"
                            displayValue={(product) => product?.name}
                            onChange={handleChange}
                            placeholder={t('order.create.selectProduct')}
                            name={"query"}
                            autoComplete={"off"}
                        />
                        <ComboboxButton className="absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                            />
                        </ComboboxButton>
                    </div>
                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <ComboboxOptions className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-sm z-10">
                            {status === Status.Pending && (
                                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                    {t('common.loading')}
                                </div>
                            )}
                            {status === Status.Complete && filteredProducts.length === 0 && values.query !== '' ? (
                                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                    {t('lead.filters.noResults')}
                                </div>
                            ) : (
                                filteredProducts.map((product) => (
                                    <ComboboxOption
                                        key={product.product_id}
                                        className={({ focus }) =>
                                            `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                                focus ? 'bg-indigo-600 text-white' : 'text-gray-900'
                                            }`
                                        }
                                        value={product}
                                    >
                                        {({ selected, focus }) => (
                                            <>
                                                <div
                                                    className={`flex flex-col gap-0`}
                                                >
                                                    <div className={`truncate font-medium`}>{product.name}</div>
                                                    <div className={"text-xs font-light truncate"}>{Price.format(product.price, t('currency.symbol'))}</div>
                                                </div>
                                                {selected || (selectedProduct && selectedProduct.product_id === product.product_id) ? (
                                                    <span
                                                        className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                                            focus ? 'text-white' : 'text-indigo-600'
                                                        }`}
                                                    >
                                                        ✓
                                                    </span>
                                                ) : null}
                                            </>
                                        )}
                                    </ComboboxOption>
                                ))
                            )}
                        </ComboboxOptions>
                    </Transition>
                </div>
            </Combobox>
        </div>
    )
})

export default ComboboxProduct;