import React, {Fragment, useCallback, useMemo, useState} from "react";
import {Datepicker} from "flowbite-react";
import {Button, Dialog, DialogPanel, DialogTitle, Field, Label, Switch} from '@headlessui/react';
import {CalendarDateRangeIcon, XMarkIcon} from '@heroicons/react/20/solid'
import useToggle from "../../../hooks/UseToggle";
import {DateFormatter} from "../../../utils/Datetime";
import {useDidUpdateEffect} from "../../../hooks/UseDidUpdateEffect";
import {customTheme} from "./customTheme";
import {useTranslation} from 'react-i18next';
import i18next from 'i18next';

const DateRange = ({onChange, optional = true}) => {
    const { t } = useTranslation();
    const {state: isOpen, toggle} = useToggle();
    const [enabled, setEnabled] = useState(!optional);

    const [startDate, setStartDate] = useState(() => {
        const now = new Date();
        return new Date(now.getFullYear(), now.getMonth(), 1);
    });
    const [endDate, setEndDate] = useState(() => {
        const now = new Date();
        return new Date(now.getFullYear(), now.getMonth() + 1, 0);
    });

    const handleStartDateChange = useCallback((date) => {
        setStartDate(date);
    }, [setStartDate]);

    const handleEndDateChange = useCallback((date) => {
        setEndDate(date);
    }, [setEndDate]);

    useDidUpdateEffect(() => {
        onChange(enabled ? [startDate, endDate] : null);
    }, [enabled, endDate, startDate]);

    const formattedDates = useMemo(() => {
        return DateFormatter.fromDate(startDate) + ' – ' + DateFormatter.fromDate(endDate);
    }, [startDate, endDate]);

    return (
        <>
            <Button
                className={`relative min-w-52 cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left border border-gray-300 focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-indigo-300 text-sm text-gray-500`}
                onClick={toggle}
            >
                <div className={(!optional || enabled) ? 'text-gray-900' : 'text-gray-500'}>
                    {(!optional || enabled) ? formattedDates : t('filters.dateRange.button')}
                </div>
                <div className={"pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"}>
                    <CalendarDateRangeIcon className={`w-5 h-5`} aria-hidden="true"/>
                </div>
            </Button>
            <Dialog open={isOpen} onClose={toggle} className="relative z-50">
                <div className="fixed inset-0 flex w-screen items-center justify-center p-4 bg-black bg-opacity-35 backdrop-blur-sm">
                    <DialogPanel className="max-w-lg bg-white shadow-lg rounded-2xl mb-24">
                        <DialogTitle className="font-bold border-b bg-gradient-to-b from-white to-gray-50 p-3 rounded-t-2xl flex justify-between items-center">
                            {t('filters.dateRange.title')}
                            <XMarkIcon className="w-5 h-5 text-gray-500 cursor-pointer hover:text-gray-400 transition-colors" onClick={toggle}/>
                        </DialogTitle>
                        <div className={"p-5"}>
                            {optional &&  <Field className="mb-4 flex justify-between items-center gap-5">
                                <Label>{t('filters.dateRange.setRange')}</Label>
                                <Switch
                                    checked={enabled}
                                    onChange={setEnabled}
                                    className="group inline-flex h-6 w-11 items-center rounded-full bg-gray-200 transition data-[checked]:bg-blue-600"
                                >
                                    <span className="size-4 translate-x-1 rounded-full bg-white transition group-data-[checked]:translate-x-6" />
                                </Switch>
                            </Field> }
                            <div
                                className={`${enabled ? 'pointer-events-auto' : 'opacity-50 pointer-events-none'} flex flex-col gap-4`}>
                                <div className={"flex gap-2 items-center flex-col sm:flex-row"}>
                                    <Datepicker
                                        language={i18next.language}
                                        weekStart={1}
                                        theme={customTheme}
                                        labelTodayButton={t('filters.dateRange.today')}
                                        labelClearButton={t('filters.dateRange.clear')}
                                        defaultDate={startDate}
                                        onSelectedDateChanged={handleStartDateChange}
                                    />
                                    <div className={"text-gray-400 text-sm"}>{t('filters.dateRange.to')}</div>
                                    <Datepicker
                                        language={i18next.language}
                                        weekStart={1}
                                        theme={customTheme}
                                        labelTodayButton={t('common.today')}
                                        labelClearButton={t('common.clear')}
                                        defaultDate={endDate}
                                        onSelectedDateChanged={handleEndDateChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </DialogPanel>
                </div>
            </Dialog>
        </>
    )
}

export default DateRange;