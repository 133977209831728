import {OrderType} from "../../../../types/global";
import Badge from "../../../../components/Badge";
import React, {useMemo} from "react";
import {Price} from "../../../../utils/Price";
import {DateFormatter} from "../../../../utils/Datetime";
import {getStatusColor, getStatusText} from "../../../../utils/OrderUtils";
import {Link} from 'react-router-dom';
import {getSourceColor, getSourceText} from "../../../../utils/FormatingUtils";
import {useTranslation} from 'react-i18next';
import {ReactComponent as UploadIcon} from "../../../../icons/paperclip-vertical.svg";
import {Tooltip} from 'flowbite-react';


const OrderTableRow = ({order}: {order: OrderType}) => {
	const { t } = useTranslation();

	const formattedDate = useMemo(() => {
		return DateFormatter.fromString(order.created_at);
	}, [order.created_at]);

	const statusColor = useMemo(() => {
		return getStatusColor(order.status);
	}, [order.status]);

	const statusText = useMemo(() => {
		return getStatusText(order.status);
	}, [order.status]);

	const total = useMemo(() => {
		const price =  order.items.reduce((acc, item) => {
			return acc + item.price_per_unit * item.quantity;
		}, 0);
		return Price.format(price, t('currency.symbol'));
	}, [order.items]);

	const attachmentSymbol = useMemo(() => {
		const tooltipContent = (
			<div className={"p-1 flex flex-col"}>
				{order.attachments?.map((attachment, index) => (
					<div key={index}>{attachment.file_name}</div>
				))}
			</div>
		);

		return order.attachments?.length ?  <Tooltip content={tooltipContent}><span className={"flex flex-row items-center ms-1 text-sm"}> • <UploadIcon/> {order.attachments.length}  </span></Tooltip> : '';
	}, [order.attachments]);

	return (
		<tr>
			<td className={"font-semibold px-2 py-3 flex flex-col"}>
				<div>{(order.lead?.name || order.lead?.business_name) || '–'}</div>
				<div className={"text-gray-500 text-xs font-medium flex items-center "}>{total}{attachmentSymbol}</div>
			</td>
			<td className={"px-2 py-3"}>{formattedDate}</td>
			<td className={"px-2 py-3"}><Badge color={statusColor}>{statusText}</Badge></td>
			<td className={"px-2 py-3"}>{order.rep?.name || '–'}</td>
			<td className={"px-2 py-3"}>{ order.source ? <Badge color={getSourceColor(order.source)}>{getSourceText(order.source)}</Badge> : '–' }</td>
			<td className={"px-2 py-3"}>
				<Link
					to={`/order/${order._id}`}
					type="button"
					className="bg-grey-200 text-blue-600 font-medium py-1.5 px-3 rounded-md border border-gray-300 no-underline text-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 transition-colors duration-200"
				>
					{t('order.tableRow.details')}
				</Link>
			</td>
		</tr>
	)
}

export default OrderTableRow;