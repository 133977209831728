import {useCallback, useRef} from "react";
import {ComboboxProductRef} from "../../components/Filters/ComboboxProduct";
import {ProductType} from "../../types/global";

const useProducts = ({setInvalid, setProducts}) => {
    const productsRef = useRef<ComboboxProductRef>(null);

    const handleAddProduct = useCallback((product?: ProductType) => {
        if (product) {
            setInvalid((prevInvalid) => ({...prevInvalid, products: false}));
            productsRef.current?.setSelectedValue(null);
            console.log("product", product)
            setProducts((prevProducts) => [...prevProducts, {product, quantity: 1, price_per_unit: (product.price / 100).toFixed(2)}])
        }
    }, [setInvalid, setProducts])

    const handleQuantityChange = useCallback((index: number, newQuantity: number) => {
        setProducts((prevProducts) =>
            prevProducts.map((item, i) =>
                i === index ? {...item, quantity: newQuantity} : item
            )
        );
    }, [setProducts]);

    const handlePriceChange = useCallback((index: number, newPrice: number | string) => {
        const parsePrice = (price: number | string): number => {
            if (typeof price === 'number') {
                return Number(price.toFixed(2));
            }

            // Replace comma with dot for parsing
            let parsedPrice = price.replace(',', '.');

            // Remove all non-numeric characters except the decimal point
            parsedPrice = parsedPrice.replace(/[^\d.]/g, '');

            // Remove leading zeros, but keep one if it's the only digit before the decimal point
            if (parsedPrice.length > 1 && parsedPrice.startsWith('0') && parsedPrice[1] !== '.') {
                parsedPrice = parsedPrice.slice(1);
            }

            // Ensure only one decimal point
            const parts = parsedPrice.split('.');
            if (parts.length > 2) {
                parsedPrice = parts[0] + '.' + parts.slice(1).join('');
            }

            // Parse to float and limit to 2 decimal places
            const floatPrice = parseFloat(parsedPrice);

            // Check if the result is a valid number
            if (isNaN(floatPrice)) {
                return 0; // or any default value you prefer
            }

            return Number(floatPrice.toFixed(2));
        };

        setProducts((prevProducts) =>
            prevProducts.map((item, i) =>
                i === index ? {...item, price_per_unit: parsePrice(newPrice)} : item
            )
        );
    }, [setProducts]);

    const handleRemoveProduct = useCallback((index: number) => {
        setProducts((prevProducts) => prevProducts.filter((_, i) => i !== index));
    }, [setProducts]);

    return {productsRef, handleAddProduct, handleQuantityChange, handlePriceChange, handleRemoveProduct};
}

export default useProducts;