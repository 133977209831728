import React from 'react';
import ReactApexChart from 'react-apexcharts';
import {Price} from "../../../../utils/Price";
import {useTranslation} from 'react-i18next';

interface RepChartProps {
    reps: {
        rep: {
            id: string;
            name: string;
        };
        overview: {
            total_quantity: number;
            total_revenue_cent: number;
        };
    }[];
}

const RepChart: React.FC<RepChartProps> = ({ reps }) => {
    const { t } = useTranslation();

    const options: ApexCharts.ApexOptions = {
        chart: {
            type: 'donut',
            background: 'transparent',
        },
        plotOptions: {
            pie: {
                donut: {
                    size: '75%',
                    background: 'transparent',
                    labels: {
                        show: true,
                        name: {
                            show: true,
                            fontSize: '22px',
                            fontFamily: 'Helvetica, Arial, sans-serif',
                            color: '#1f2937',
                            offsetY: -10
                        },
                        value: {
                            show: true,
                            fontSize: '26px',
                            fontFamily: 'Helvetica, Arial, sans-serif',
                            color: '#111827',
                            offsetY: 16,
                            formatter: function (val) {
                                return Price.format(Number(val) * 100, t('currency.symbol'))
                            }
                        },
                        total: {
                            show: true,
                            label: t('reports.repChart.total'),
                            fontSize: '16px',
                            fontWeight: 600,
                            color: '#4b5563',
                            formatter: function (w) {
                                return Price.format(w.globals.seriesTotals.reduce((a, b) => {
                                    return a + b
                                }, 0) * 100, t('currency.symbol'))
                            }
                        }
                    }
                }
            }
        },
        dataLabels: {
            enabled: false
        },
        fill: {
            opacity: 1
        },
        stroke: {
            width: 2,
            colors: ['#ffffff']
        },
        legend: {
            position: 'bottom',
            offsetY: 0,
            height: 40,
            labels: {
                colors: '#4b5563',
            },
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    position: 'bottom'
                }
            }
        }],
        labels: reps.map(r => r.rep.name),
        colors: [
            '#3b82f6', '#10b981', '#f59e0b', '#ef4444', '#8b5cf6',
            '#ec4899', '#06b6d4', '#14b8a6', '#f97316', '#84cc16'
        ],
        tooltip: {
            theme: 'dark',
            y: {
                formatter: function(value) {
                    return Price.format(value * 100, t('currency.symbol'))
                }
            }
        }
    };

    const series = reps.map(r => r.overview.total_revenue_cent / 100);

    return (
        <div className="bg-white bg-opacity-65 p-6 rounded-xl shadow">
            <h2 className="text-xl font-bold text-gray-900 mb-4">{t('reports.repChart.title')}</h2>
            <ReactApexChart
                options={options}
                series={series}
                type="donut"
                height={350}
            />
        </div>
    );
};

export default RepChart;