import React from 'react';
import {useTranslation} from 'react-i18next';
import {Spinner} from "flowbite-react";

type FormButtonProps = {
    onClick: (e: any | undefined) => void | Promise<void>;
    isPending: boolean;
    label?: string;
}

const FormButton: React.FC<FormButtonProps> = ({ onClick, isPending, label }) => {
    const { t } = useTranslation();

    return (
        <button
            className={`flex gap-2 items-center justify-center bg-blue-600 text-md mx-auto w-full shadow-sm text-white font-medium py-2 px-4 rounded-lg hover:bg-blue-700 transition-colors ${isPending ? 'opacity-70 cursor-not-allowed' : ''}`}
            type={"submit"}
            onClick={onClick}
            disabled={isPending}
        >
            {isPending ? <Spinner size={"sm"} /> : (label ?? t('common.save'))}
        </button>
    );
};

export default FormButton;