import Dashboard from "../../layouts/Dashboard";
import useIndex from "../../hooks/UseIndex";
import {API_URL} from "../../config";
import {OrderType} from "../../types/global";
import OrderTableRow from "./compoments/OrderTableRow";
import Pagination from "../../components/Pagination";
import React, {Fragment, useMemo, useState} from "react";
import SearchBar from "../../components/Dashboard/SearchBar";
import RefreshButton from "../../components/Dashboard/RefreshButton";
import {Status} from "../../types/enums";
import {Spinner} from "flowbite-react";
import FilterButton from "../../components/Dashboard/FilterButton";
import {DateFormatter} from "../../utils/Datetime";
import useEffectOnChange from "../../hooks/UseEffectOnValueChange";
import FiltersContainer from "../../components/Dashboard/FiltersContainer";
import useRefreshButtonNum from "../../hooks/Dashboard/UseRefreshButtonNum";
import useFilters from "../../hooks/Dashboard/UseFilters";
import DateRange from "../../components/Filters/DateRange";
import ComboboxLead from "../../components/Filters/ComboboxLead";
import ComboboxRep from "../../components/Filters/ComboboxRep";
import ListboxStatus from "../../components/Filters/ListboxStatus";
import ListboxSource from "../../components/Filters/ListboxSource";
import {useModelEvent} from "../../hooks/UseModelEvents";
import HeaderButton from "../../components/Dashboard/HeaderButton";
import {useNavigate} from "react-router-dom";
import {useTranslation} from 'react-i18next';

const FiltersComponents = [
    {id: "daterange", Component: DateRange},
    {id: "lead", Component: ComboboxLead},
    {id: "rep", Component: ComboboxRep},
    {id: "status", Component: ListboxStatus},
    {id: "source", Component: ListboxSource},
]

const OrderIndex = () => {
    const navigate = useNavigate();
    const limit = useMemo(() => 10, []);
    const endpoint = useMemo(() => `${API_URL}/order`, []);

    const {data, status, fetchData, meta, setPage} = useIndex<OrderType>(endpoint, limit, true);
    const [search, setSearch] = useState("");

    const {refreshButtonNum, incrementRefreshButtonNum} = useRefreshButtonNum();
    const {isFilterActive, toggleFilters, filters, activeFilters, handleFilterChange} = useFilters();

    useEffectOnChange(() => {
        // const params = {page: 1}
        const params = {};
        if (search) params['search'] = search;

        // Build filters
        if (isFilterActive) {
            filters.forEach((filter) => {
                if (filter.value) {
                    switch (filter.id) {
                        case 'daterange':
                            params['start'] = DateFormatter.convertToISO8601(filter.value[0]);
                            params['end'] = DateFormatter.convertToISO8601(filter.value[1]);
                            break;
                        case 'lead':
                            params['lead_id'] = filter.value._id;
                            break;
                        case 'rep':
                            params['rep_id'] = filter.value._id;
                            break;
                        case 'status':
                            params['status'] = filter.value;
                            break;
                        case 'source':
                            params['source'] = filter.value;
                            break;
                        default:
                            break;
                    }
                }
            })
        }

        fetchData(params);
    }, [filters, search, refreshButtonNum]);

    const eventsToListen = useMemo(() => ["OnCreate", "OnDelete", "OnUpdate"], []);
    useModelEvent('Order', eventsToListen, incrementRefreshButtonNum);
    useModelEvent('Lead', eventsToListen, incrementRefreshButtonNum);

    const isPending = useMemo(() => status === Status.Pending || status === Status.NotLoaded, [status]);

    const { t } = useTranslation();

    const headerButton = useMemo(() => <HeaderButton label={t('order.index.newOrder')} onClick={() => navigate('/order/create')} />, [navigate, t]);

    return (<Dashboard heading={t('order.index.title')} headerButton={headerButton}>
            <div className={"flex justify-between items-center gap-4"}>
                <SearchBar onChange={setSearch}/>

                <div className={"flex gap-3"}>
                    <RefreshButton isPending={isPending} onClick={incrementRefreshButtonNum}/>
                    <FilterButton onClick={toggleFilters} isActive={isFilterActive} activeFilters={activeFilters}/>
                </div>
            </div>

            <FiltersContainer onChange={handleFilterChange} show={isFilterActive} filters={FiltersComponents}/>

            {data && <>
                    <div className="overflow-x-auto w-full">
                        <table className="w-full mt-5 min-w-[600px]">
                            <thead className="text-sm sm:text-md text-left bg-gray-200 uppercase text-gray-500">
                            <tr className={"rounded-lg"}>
                                <th className={"font-normal py-1.5 px-2"}>{t('order.index.table.lead')}</th>
                                <th className={"font-normal py-1.5 px-2"}>{t('order.index.table.date')}</th>
                                <th className={"font-normal py-1.5 px-2"}>{t('order.index.table.status')}</th>
                                <th className={"font-normal py-1.5 px-2"}>{t('order.index.table.representative')}</th>
                                <th className={"font-normal py-1.5 px-2"}>{t('order.index.table.category')}</th>
                                <th className={"font-normal py-1.5 px-2"}></th>
                            </tr>
                            </thead>
                            <tbody className={"text-xs sm:text-sm"}>
                            {
                                data.length === 0 ?
                                    <>
                                        {
                                            isPending ? <tr>
                                                    <td colSpan={6} className={"text-center py-6"}><Spinner
                                                        size="lg"
                                                        className={"mx-auto"}/>
                                                    </td>
                                                </tr> :
                                                <tr>
                                                    <td colSpan={6} className={"text-center text-gray-500 py-4"}>
                                                        {t('common.noResults')}
                                                    </td>
                                                </tr>
                                        }
                                    </> :
                                    data.map((order) => (
                                        <OrderTableRow key={order._id} order={order}/>
                                    ))
                            }
                            </tbody>
                        </table>
                    </div>
                    <Pagination meta={meta} setPage={setPage}/>
                </>
            }

        </Dashboard>
    );
}

export default OrderIndex;