import i18next from 'i18next';

export const getStatusColor = (status) => {
    switch (status) {
        case 'pending':
            return 'gray';
        case 'completed':
            return 'green';
        case 'canceled':
            return 'red';
        default:
            return 'gray';
    }
}

export const getStatusText = (status) => {
    return i18next.t(`utils.orderStatus.${status}`, { defaultValue: '–' });
}

export const getSource = (source) => {
    return i18next.t(`utils.orderSource.${source}`, { defaultValue: '–' });
}