import React, {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useAuth} from '../../contexts/AuthContext';
import Dashboard from "../../layouts/Dashboard";
import ComboboxRep from '../../components/Filters/ComboboxRep';
import {API_URL} from '../../config';
import {RepType} from '../../types/global';
import {useNavigate} from "react-router-dom";
import {usePopupMessage} from "../../contexts/PopupMessageContext";
import useForm from "../../hooks/UseForm";
import {FormContainer, FormSection} from "../../components/Form/FormStyles";
import InputField from "../../components/Form/InputField";
import FormButton from "../../components/Form/FormButtom";
import {convertEmptyStringToNullInObject} from "../../utils/ApiUtils";


const StockCreate = () => {
    const {t} = useTranslation();
    const {authToken} = useAuth();
    const navigate = useNavigate();
    const {push: pushMessage} = usePopupMessage();
    const [isPending, setIsPending] = useState(false);
    const {values, handleChange, invalid, setInvalid} = useForm({
        name: "",
    });

    const [rep, setRep] = useState<RepType | null>(null);

    const submit = useCallback(async (e) => {
        e.preventDefault();

        const fieldValues = convertEmptyStringToNullInObject(values);

        const payload = {
            ...fieldValues,
            rep_id: rep?._id || null,
        }

        setIsPending(true);
        const response = await fetch(`${API_URL}/stock`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            },
            body: JSON.stringify(payload)
        })
        setIsPending(false);

        const responseData = await response.json();

        if (response.ok) {
            navigate(`/stock/${responseData._id}`);
        } else {
            pushMessage(responseData.message);
        }

    }, [values, rep, authToken, pushMessage, navigate]);


    return (
        <Dashboard heading={t('stock.create.title')}>
            <FormContainer>

                <FormSection label={t('stock.create.general')}>
                    <InputField
                        id="name"
                        name="name"
                        type="text"
                        placeholder={t('stock.create.name')}
                        label={t('stock.create.name')}
                        onChange={handleChange}
                    />
                    <div className={"flex items-center justify-between"}>
                        <div className="text-sm font-medium text-gray-900">{t('common.representative')}</div>
                        <div>
                            <ComboboxRep onChange={setRep}/>
                        </div>
                    </div>
                </FormSection>

                <FormButton onClick={submit} isPending={isPending} label={t('common.create')}/>

            </FormContainer>
        </Dashboard>
    )
}

export default StockCreate;