import React, {useCallback, useEffect, useRef, useState} from 'react';
import {ChatBoxProps} from './index.d';
import styles from './style.module.scss';
import {ReactComponent as UploadIcon} from "../../../../icons/paperclip-vertical.svg";
import {ReactComponent as ArrowUp} from '../../../../icons/arrow-small-up.svg';
import {ReactComponent as CrossIcon} from "../../../../icons/cross.svg";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {resetReplyTo} from "../../../../slices/ReplyToSlice";
import MenuModal from "../../../MenuModal/index";
import {useModal} from "../../../../contexts/ModalContext";
import {FileRejection, useDropzone} from "react-dropzone";
import FileComponent from "./components/FileComponent/index";
import {usePopupMessage} from "../../../../contexts/PopupMessageContext";
import {API_URL} from "../../../../config";
import ActivityIndicator from "../../../ActivityIndicator";
import {UploadedFile} from "../../../../types/global";
import {useAuth} from "../../../../contexts/AuthContext";
import {useTranslation} from 'react-i18next';

const ChatBox = ({onSubmit, isCompletionPending}: ChatBoxProps) => {
    const { t } = useTranslation();
    const {push: pushModal} = useModal();
    const {push: pushMessage} = usePopupMessage();
    const textareaRef = useRef(null);
    const [files, setFiles] = useState<File[]>([]);
    const fileInputRef = useRef(null);
    const [isFileUploadPending, setIsFileUploadPending] = useState(false);
    const {authToken} = useAuth();

    // @ts-ignore
    const replyToState = useSelector(state => state.replyTo, shallowEqual);
    const dispatch = useDispatch();

    const clearReplyTo = useCallback(() => {
        dispatch(resetReplyTo())
    }, [dispatch]);

    const resetHeight = useCallback(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight + 35}px`;
        }
    }, [textareaRef]);

    useEffect(() => {
        resetHeight();
    }, [resetHeight]);

    const onPressMenuButton = useCallback(() => {
        pushModal(<MenuModal/>);
    }, [pushModal]);

    const uploadFiles = useCallback(async () => {
        const formData = new FormData();
        Array.from(files).forEach(file => {
            formData.append('files', file);
        });

        setIsFileUploadPending(true);
        const response = await fetch(`${API_URL}/upload`, {
            method: 'POST',
            body: formData,
            headers: {
                'Authorization': `Bearer ${authToken}`
            }
        });
        setIsFileUploadPending(false);

        if (!response.ok) {
            const errorData = await response.json();
            pushMessage(t('chat.chatBox.uploadError'));
            console.log(errorData.error);
            return false;
        } else {
            const responseData = await response.json();
            setFiles([]);
            return responseData.files;
        }
    }, [files, pushMessage, t]);

    const handleSubmit = useCallback(async (event?) => {
        event?.preventDefault();
        if (isCompletionPending || isFileUploadPending || textareaRef.current.value.trim() === '') return;

        const uploadedFiles: UploadedFile[] = [];
        if (files.length > 0) {
            const uploadRes = await uploadFiles();
            if (uploadRes === false) return;
            uploadedFiles.push(...uploadRes);
        }

        textareaRef.current.value = textareaRef.current.value.trim();

        const payloadText = replyToState.sendAs
            ? `<quote>${replyToState.sendAs}</quote>\n\n${textareaRef.current.value}`
            : textareaRef.current.value;

        const sendRes = onSubmit(payloadText, uploadedFiles);
        if (sendRes !== false) {
            textareaRef.current.value = "";
            resetHeight();
            // clearReplyTo();
        }

    }, [isCompletionPending, isFileUploadPending, files.length, replyToState.sendAs, onSubmit, uploadFiles, resetHeight, clearReplyTo]);

    const handleKeyDown = useCallback((event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            handleSubmit();
        }
    }, [handleSubmit]);

    const onDrop = useCallback((acceptedFiles) => {
        setFiles(prevFiles => [...prevFiles, ...acceptedFiles]);
    }, []);

    const onDropRejected = useCallback((fileRejections: FileRejection[]) => {
        console.log("rejected", fileRejections);
        const unsupportedFormatsString = fileRejections.map(({
                                                                 file,
                                                                 errors
                                                             }) => file.name.split('.').pop().toLowerCase()).join('\n');
        pushMessage(t('chat.chatBox.unsupportedFormat') + " " + unsupportedFormatsString);
    }, [pushMessage, t]);

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop,
        onDropRejected,
        multiple: true,
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpg', '.jpeg'],
            'image/gif': ['.gif'],
            'application/pdf': ['.pdf'],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
            'application/msword': ['.doc'],
            'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
            'text/plain': ['.txt'],
            'application/json': ['.json']
        },
        disabled: isFileUploadPending,
        noClick: true,
    });

    const onPressUploadButton = () => fileInputRef.current.click();

    const removeFile = useCallback((file) => {
        setFiles(prevFiles => prevFiles.filter(f => f !== file));
    }, []);

    return (
        <form className={`${styles.chatBoxWrapper} bg-gray-900`}
              onSubmit={handleSubmit}
        >

            <div {...getRootProps()}
                 className={`${styles.chatBoxContainer} ${isDragActive ? styles.activeDropzone : ''}`}
            >
                <input {...getInputProps()} ref={fileInputRef} className={styles.hidden} hidden />
                {replyToState.displayAs && <div className={styles.replyToContainer}>
                    <div className={styles.replyToContent}>
                        {replyToState.displayAs}
                    </div>
                    <div className={styles.replyToCloseButton} onClick={clearReplyTo}>
                        <CrossIcon />
                    </div>
                </div>}

                {files.length > 0 && (
                    <div className={styles.selectedFiles}>
                        {files.map((file, index) => (
                            <FileComponent file={file}
                                           key={index}
                                           onDelete={() => removeFile(file)}
                                           isFileUploadPending={isFileUploadPending}
                            />
                        ))}
                    </div>
                )}

                <div className={styles.chatBoxRow}>
                    <textarea ref={textareaRef}
                              onInput={resetHeight}
                              className={styles.chatBox}
                              placeholder={t('chat.chatBox.placeholder')}
                              rows={1}
                              onKeyDown={handleKeyDown}
                              name={"message"}
                              autoFocus={true}
                    ></textarea>
                    <div className={styles.buttonContainer}>
                        <button className={styles.menuButton}
                                onClick={onPressUploadButton}
                                aria-label={t('chat.chatBox.uploadButton')}>
                            <UploadIcon/>
                        </button>
                        <button type="submit"
                                className={`button ${styles.sendButton}`}
                                disabled={isCompletionPending || isFileUploadPending}
                                aria-label={t('chat.chatBox.sendButton')}>
                            { isFileUploadPending
                                ? <ActivityIndicator size={18} color={"#1D1128"} />
                                : <ArrowUp/>
                            }
                        </button>
                    </div>


                </div>
                {isDragActive &&
                    <div className={styles.activeDropzoneOverlay}>
                        <div className={styles.activeDropzoneIcon}>
                            <UploadIcon/>
                        </div>
                        <div className={styles.activeDropzoneText}>
                            {t('chat.chatBox.dropzoneActive')}
                        </div>
                    </div>
                }

            </div>
        </form>
    );
}

export default ChatBox;