import React, {createContext, useCallback, useContext, useEffect} from 'react';
import Cookies from 'js-cookie';
import {LocaleType} from "../types/global";
import i18n from 'i18next';
import {useAuth} from "./AuthContext";

type LocaleContextType = {
    setLocale: (locale: LocaleType) => void;
}

// Create a context with default undefined value
const LocaleContext = createContext<LocaleContextType | undefined>(undefined);

// Create a provider component
export const LocaleProvider: React.FC<any> = ({ children }) => {
    const {user} = useAuth();

    const setLocale = useCallback((locale: LocaleType) => {
        i18n.changeLanguage(locale);
        Cookies.set('locale', locale, { expires: 365 });
    }, []);

    useEffect(() => {
        const savedLocale = Cookies.get('locale');
        if (savedLocale) {
            i18n.changeLanguage(savedLocale);
        }
    }, []);

    useEffect(() => {
        if (user?.locale) {
            setLocale(user.locale);
        }
    }, [setLocale, user]);

    return (
        <LocaleContext.Provider value={{setLocale}}>
            {children}
        </LocaleContext.Provider>
    );
};

// Create a hook to use the LocaleContext, this is a common pattern and helps avoid prop drilling
export const useLocale = () => {
    const context = useContext(LocaleContext);
    if (context === undefined) {
        throw new Error('useLocale must be used within an LocaleProvider');
    }
    return context;
};