import {FunnelIcon} from "@heroicons/react/20/solid";
import {Transition} from "@headlessui/react";
import React, {Fragment} from "react";
import {Badge} from "flowbite-react";
import {useTranslation} from 'react-i18next';

const FilterButton = ({activeFilters = 0, isActive, onClick}: {activeFilters?: number, isActive: boolean, onClick: () => void}) => {
    const { t } = useTranslation();

    return (
        <button
            onClick={onClick}
            className={`bg-grey-200 font-medium py-1.5 px-3 rounded-lg border-2 border-gray-300 no-underline text-sm hover:bg-gray-50 focus:outline-none transition-all duration-200 flex gap-2 items-center ring-gray-300 ring-offset-1 hover:ring
                              ${isActive ? 'text-purple-800' : 'text-gray-900'}
                              ${isActive ? 'border-purple-800 bg-gray-50' : 'border-gray-300'}`}>
            <FunnelIcon className={"w-4 h-4"}/>
            {t('common.filters')}
            <Transition
                show={activeFilters > 0}
                as={Fragment}
                enter="transition ease-out duration-150"
                enterFrom="transform opacity-0 translate-x-full"
                enterTo="transform opacity-100 translate-x-0"
                leave="transition ease-in duration-100"
                leaveFrom="transform opacity-100 translate-x-0"
                leaveTo="transform opacity-0 translate-x-full"
            >
                <div><Badge color={"purple"}>{Math.max(activeFilters, 1)}</Badge></div>
            </Transition>
        </button>
    )
}

export default FilterButton;