import Dashboard from "../../layouts/Dashboard";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import useForm from "../../hooks/UseForm";
import {t} from "i18next";
import CustomListbox from "../../components/Form/Listbox";
import {BusinessType, LocaleType} from "../../types/global";
import {API_URL} from "../../config";
import {useAuth} from "../../contexts/AuthContext";
import {getInvalidFieldsFromResponse} from "../../utils/ApiUtils";
import FormButton from "../../components/Form/FormButtom";
import InputField from "../../components/Form/InputField";
import {FormContainer, FormInputContainer, FormSection} from "../../components/Form/FormStyles";
import {useLocale} from "../../contexts/LocaleContext";

type LanguageOptionType = { value: LocaleType, label: string };

const LANGUAGE_OPTIONS: LanguageOptionType[] = [
    {
        value: 'sk-SK',
        label: 'Slovenčina'
    },
    {
        value: 'cs-CZ',
        label: 'Čeština'
    },
    {
        value: 'en-GB',
        label: 'English'
    }
];

const BusinessSettings = () => {

    const endpoint = useMemo(() => `${API_URL}/business`, []);
    const {authToken} = useAuth();
    const {setLocale} = useLocale();

    const [isLoading, setIsLoading] = useState(true);
    const [isUpdating, setIsUpdating] = useState(false);

    const {
        values: data,
        handleChange,
        setValues: setData,
        invalid,
        setInvalid,
        resetInvalid,
        changeValue,
        setInitialValues,
        getChangedValues
    } = useForm<Partial<BusinessType>>({
        name: null,
        locale: null,
        counties: null,
        custom_instructions: null,
    });

    useEffect(() => {
        const fetchData = async () => {
            const res = await fetch(endpoint,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${authToken}`,
                    }
                });

            if (res.ok) {
                const data = await res.json();
                setInitialValues(data);
                setIsLoading(false);
            }
        }
        fetchData();
    }, [authToken, endpoint, setInitialValues]);

    const submit = useCallback(async () => {
        setIsUpdating(true);
        const res = await fetch(endpoint,
            {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`,
                },
                body: JSON.stringify(getChangedValues())
            });

        if (res.ok) {
            const resData: BusinessType = await res.json();
            setInitialValues(resData);
            setIsUpdating(false);
            resetInvalid();
            setLocale(resData.locale);
        } else {
            setIsUpdating(false);
            const invalidFields = getInvalidFieldsFromResponse(await res.json());
            setInvalid((prev) => {
                const updatedInvalid = {...prev};
                Object.keys(prev).forEach((key) => {
                    updatedInvalid[key] = invalidFields.includes(key);
                });
                return updatedInvalid;
            });
        }
    }, [endpoint, authToken, getChangedValues, setInitialValues, resetInvalid, setLocale, setInvalid]);

    const countiesSeparatedByComma = useMemo(() => {
        return data.counties ? data.counties.join(', ') : '–';
    }, [data.counties]);

    const handleLocaleChange = useCallback((option: LanguageOptionType) => {
        changeValue('locale', option.value);
    }, [changeValue]);

    return (
        <Dashboard heading={t("business.settingTitle")}>
            {/* Main Content */}
            {!isLoading && <>
                <FormContainer>
                    <FormSection label={t("business.general")}>

                        <InputField
                            id="name"
                            name="name"
                            type="text"
                            placeholder={t("business.name")}
                            label={t("business.name")}
                            onChange={handleChange}
                            value={data.name}
                            isInvalid={invalid.name}
                        />

                        <FormInputContainer label={t("business.language")}>
                            <CustomListbox
                                options={LANGUAGE_OPTIONS}
                                onChange={handleLocaleChange}
                                defaultValue={data.locale}
                            />
                        </FormInputContainer>

                        <FormInputContainer label={t("business.counties")}>
                            <p className="text-sm text-gray-600 cursor-not-allowed">{countiesSeparatedByComma}</p>
                        </FormInputContainer>


                        <FormInputContainer label={t("business.customInstructions")}>
                            <textarea
                                id="custom_instructions"
                                name="custom_instructions"
                                placeholder={t('business.customInstructionsPlaceholder')}
                                rows={3}
                                className={`block w-full rounded-lg border-0 py-1.5 text-gray-900 ring-1 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${invalid.custom_instructions ? 'ring-red-400 animationShake' : 'ring-gray-300'}`}
                                value={data.custom_instructions ?? undefined}
                                onChange={handleChange}
                            />
                        </FormInputContainer>

                    </FormSection>

                    <FormButton
                        onClick={submit}
                        isPending={isUpdating}
                        label={t('common.save')}
                    />
                </FormContainer>
            </>}
        </Dashboard>
    )
}


export default BusinessSettings;