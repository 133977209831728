import Dashboard from "../../layouts/Dashboard";
import {API_URL} from "../../config";
import {StockType} from "../../types/global";
import useResource from "../../hooks/UseResource";
import {useNavigate, useParams} from "react-router-dom";
import {field} from "../../utils/FormatingUtils";
import {useModelDelete, useModelEvent, useModelUpdate} from "../../hooks/UseModelEvents";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Spinner} from "flowbite-react";
import {useTranslation} from 'react-i18next';
import StockTransactionsTable from "./components/StockTransactionsTable";
import {ArrowsRightLeftIcon, MinusIcon, PlusIcon} from "@heroicons/react/20/solid";
import TopUpAndWithdrawDialog from "./components/TopUpAndWithdrawDialog";
import useToggle from "../../hooks/UseToggle";
import TransferDialog from "./components/TransferDialog";

const StockShow = () => {
    const {t} = useTranslation();
    const {stock_id} = useParams<{ stock_id: string }>();
    const navigate = useNavigate();

    const endpoint = useMemo(() => `${API_URL}/stock/${stock_id}`, [stock_id]);
    const {
        data: stock,
        isPending,
        fetchResource,
    } = useResource<StockType>(endpoint);

    const [chatContext, setChatContext] = useState(undefined);
    useEffect(() => {
        if (stock) {
            setChatContext({
                displayAs: `${t('stock.show.title')} ${stock.name}`,
                sendAs: `${t('stock.show.title')} ${stock._id}`,
            })
        }
    }, [stock, t]);

    useModelUpdate('Stock', stock_id, fetchResource);
    useModelDelete('Stock', stock_id, useCallback(() => {
        navigate('/stock');
    }, [navigate]));

    const eventsToListen = useMemo(() => ["OnCreate", "OnDelete", "OnUpdate"], []);
    useModelEvent('StockTransaction', eventsToListen, fetchResource);
    useModelEvent('Order', eventsToListen, fetchResource);

    // const stockTransactionEndpoint = useMemo(() => `${endpoint}/transactions`, [endpoint]);
    //
    // const {data: stockTransactions, status: stockTransactionsStatus, fetchData: fetchStockTransactions, meta, setPage} = useIndex<StockAggregatedTransactionType>(stockTransactionEndpoint, 10);
    // const isStockTransactionPending = useMemo(() => stockTransactionsStatus === Status.Pending || stockTransactionsStatus === Status.NotLoaded, [stockTransactionsStatus]);

    const {state: isTopUpOpen, toggle: toggleTopUp} = useToggle();
    const {state: isWithdrawOpen, toggle: toggleWithdraw} = useToggle();
    const {state: isTransferOpen, toggle: toggleTransfer} = useToggle();


    const headerButtons = useMemo(() => {
        return <div className={"flex gap-3"}>
            <button
                className="bg-grey-200 font-medium py-1.5 px-3 rounded-lg border-2 border-gray-300 no-underline text-sm hover:bg-gray-50 focus:outline-none transition-all duration-200 flex gap-2.5 items-center ring-gray-300 ring-offset-1 hover:ring text-gray-900"
                onClick={toggleTopUp}
            >
                <PlusIcon className="-mr-1 h-4 w-4 text-gray-400" aria-hidden="true"/>
                {t('stock.show.buttons.topUp')}
            </button>
            <button
                className="bg-grey-200 font-medium py-1.5 px-3 rounded-lg border-2 border-gray-300 no-underline text-sm hover:bg-gray-50 focus:outline-none transition-all duration-200 flex gap-2.5 items-center ring-gray-300 ring-offset-1 hover:ring text-gray-900"
                onClick={toggleWithdraw}
            >
                <MinusIcon className="-mr-1 h-4 w-4 text-gray-400" aria-hidden="true"/>
                {t('stock.show.buttons.withdraw')}
            </button>
            <button
                className="bg-grey-200 font-medium py-1.5 px-3 rounded-lg border-2 border-gray-300 no-underline text-sm hover:bg-gray-50 focus:outline-none transition-all duration-200 flex gap-2.5 items-center ring-gray-300 ring-offset-1 hover:ring text-gray-900"
                onClick={toggleTransfer}
            >
                <ArrowsRightLeftIcon className="-mr-1 h-4 w-4 text-gray-400" aria-hidden="true"/>
                {t('stock.show.buttons.transfer')}
            </button>
        </div>
    }, [t, toggleTopUp, toggleWithdraw]);

    return (
        <Dashboard heading={t('stock.show.title')} chatContext={chatContext} headerButton={headerButtons}>
            {stock ? (
                <div className="border-t border-gray-200">
                    <dl className="divide-y divide-gray-200">
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">{t('stock.show.general')}</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                <div className={"flex gap-3"}>
                                    <div className={"font-medium"}>{t('common.name')}</div>
                                    <div>{field(stock.name)}</div>
                                </div>
                                <div className={"flex gap-3"}>
                                    <div className={"font-medium"}>{t('common.representative')}</div>
                                    <div>{field(stock.rep?.name)}</div>
                                </div>
                            </dd>
                        </div>

                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">{t('stock.show.state')}</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                {
                                    stock.quantity.map((quantity, index) => (
                                        <div key={index} className={"flex gap-3"}>
                                            <div className={"font-medium"}>{quantity.product_name}</div>
                                            <div>{quantity.final_quantity}</div>
                                        </div>
                                    ))
                                }
                            </dd>
                        </div>
                    </dl>

                        <div className="px-4 py-6 sm:px-0">
                            <StockTransactionsTable stock={stock} />
                        </div>


                </div>
            ) : (
                isPending &&
                <div className={"flex justify-center"}><Spinner size="lg" className={"mt-6 mx-auto"}/></div>
            )}
            {
                (isTopUpOpen || isWithdrawOpen) && <TopUpAndWithdrawDialog isOpen={isTopUpOpen || isWithdrawOpen} onClose={isTopUpOpen ? toggleTopUp : toggleWithdraw} stockId={stock_id} type={isTopUpOpen ? "topUp" : "withdraw"} />
            }
            {
                isTransferOpen && <TransferDialog isOpen={isTransferOpen} onClose={toggleTransfer} stockId={stock_id} />
            }
        </Dashboard>
    );
}

export default StockShow;