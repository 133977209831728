import {useCallback, useState} from "react";
import {useAuth} from "../contexts/AuthContext";
import {usePopupMessage} from "../contexts/PopupMessageContext";
import {useTranslation} from "react-i18next";

const useFileDownload = ({downloadLink, fileName}) => {
    const [isPending, setIsPending] = useState(false);
    const {authToken} = useAuth();
    const {push: pushMessage} = usePopupMessage();
    const {t} = useTranslation();

    const downloadFile = useCallback(async () => {
        setIsPending(true);
        try {
            const response = await fetch(downloadLink, {
                headers: {
                    'Authorization': `Bearer ${authToken}`
                }
            });

            if (!response.ok) throw new Error('Download failed');

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Download error:', error);
            pushMessage(t("common.errorMessage"));
        }
        setIsPending(false);
    }, [authToken, downloadLink, fileName, pushMessage, t]);

    return { downloadFile, isPending };
}

export default useFileDownload;