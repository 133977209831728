import {Link} from 'react-router-dom';
import React, {useCallback, useState} from "react";
import {StockAggregatedTransactionType} from "../../../types/global";
import {field} from "../../../utils/FormatingUtils";
import {DateFormatter} from "../../../utils/Datetime";
import {useTranslation} from "react-i18next";
import {ArrowTopRightOnSquareIcon, TrashIcon} from "@heroicons/react/24/solid";
import {useAuth} from "../../../contexts/AuthContext";
import {API_URL} from "../../../config";
import {Spinner} from "flowbite-react";
import {usePopupMessage} from "../../../contexts/PopupMessageContext";

const StockTransactionTableRow = ({transaction}: { transaction: StockAggregatedTransactionType }) => {

    const { t } = useTranslation();
    const {authToken} = useAuth();
    const [isDeletePending, setIsDeletePending] = useState(false);
    const {push: pushMessage} = usePopupMessage();

    const deleteTransaction = useCallback(async () => {
        const response = await fetch(`${API_URL}/stock/${transaction.stock_id}/transaction/${transaction.stock_transaction_id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${authToken}`,
                'Content-Type': 'application/json',
            }
        });

        if (!response.ok) {
            const res = await response.json();
            console.error(res);
            setIsDeletePending(false);
            pushMessage(t("common.error"));
        }

    }, [authToken, pushMessage, t, transaction.stock_id, transaction.stock_transaction_id]);

    const unsetStockAttributionFromOrder = useCallback(async () => {
        const response = await fetch(`${API_URL}/order/${transaction.order_id}`, {
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${authToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({stock_id: null})
        });

        if (!response.ok) {
            const res = await response.json();
            console.error(res);
            setIsDeletePending(false);
            pushMessage(t("common.error"));
        }
    }, [authToken, pushMessage, t, transaction.order_id]);

    const deleteResource = useCallback(async () => {
        if (!transaction.stock_transaction_id && !transaction.order_id) {
            return;
        }

        setIsDeletePending(true);
        if (transaction.order_id) {
            await unsetStockAttributionFromOrder();
        } else if (transaction.stock_transaction_id) {
            await deleteTransaction();
        }
    }, [deleteTransaction, transaction.order_id, transaction.stock_transaction_id, unsetStockAttributionFromOrder]);

    const deleteAlert = useCallback(async () => {
        if (window.confirm(t("common.deleteConfirmation"))) {
            await deleteResource();
        }
    }, [deleteResource, t]);

    return (
        <tr>
            <td className={"font-normal px-2 py-3"}>{field(transaction.product_name)}</td>
            <td className={"font-normal px-2 py-3"}>{field(transaction.quantity)}</td>
            <td className={"font-normal px-2 py-3"}>{field(transaction.description)}</td>
            <td className={"font-normal px-2 py-3"}>{DateFormatter.fromString(transaction.created_at)}</td>
            <td className={"px-2 py-3"}>
                {
                    transaction.order_id &&
                    <Link
                        to={`/order/${transaction.order_id}`}
                        type="button"
                        className="inline-flex hover:text-gray-800 items-center justify-center gap-2 bg-grey-200 text-gray-800 font-medium py-1.5 px-3 rounded-md border border-gray-300 no-underline text-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 transition-colors duration-200"
                    >
                        <ArrowTopRightOnSquareIcon className={"text-gray-400 w-4 h-4"} /> {t("stock.show.transactionsTable.order")}
                    </Link>
                }
            </td>
            <td className={"px-2 py-3 inner-flex items-center justify-center"}>
                { isDeletePending ? <Spinner size="sm" /> : <div
                        onClick={deleteAlert}
                        role="button"
                        className="inner-flex items-center justify-center text-gray-500 hover:text-gray-800 transition-colors duration-200"
                    >
                        <TrashIcon className={"w-4 h-4"} aria-label={t("common.delete")} />
                    </div> }
            </td>
        </tr>
    )
}

export default StockTransactionTableRow;