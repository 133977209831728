import Dashboard from "../../layouts/Dashboard";
import Badge from "../../components/Badge";
import {API_URL} from "../../config";
import {OrderType} from "../../types/global";
import useResource from "../../hooks/UseResource";
import {useNavigate, useParams} from "react-router-dom";
import {Price} from "../../utils/Price";
import {DateFormatter} from "../../utils/Datetime";
import {getStatusColor, getStatusText} from "../../utils/OrderUtils";
import {field, getSourceColor, getSourceText} from "../../utils/FormatingUtils";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useModelDelete, useModelUpdate} from "../../hooks/UseModelEvents";
import {Spinner} from "flowbite-react";
import MarkAsButton from "./MarkAsButton";
import {useTranslation} from "react-i18next";
import useAttachmentUpload from "../../hooks/UseAttachmentUpload";
import {ReactComponent as UploadIcon} from "../../icons/paperclip-vertical.svg";
import {useAuth} from "../../contexts/AuthContext";
import {usePopupMessage} from "../../contexts/PopupMessageContext";
import Attachment from "./Attachment";

const OrderShow = () => {
    const {t} = useTranslation();
    const {order_id} = useParams<{ order_id: string }>();
    const navigate = useNavigate();
    const {authToken} = useAuth();
    const {push: pushMessage} = usePopupMessage();

    const endpoint = useMemo(() => `${API_URL}/order/${order_id}`, [order_id]);
    const {
        data,
        setData,
        forceSetData,
        isPending,
        resourceEmpty,
        fetchResource,
        deleteStatus,
        updateResource,
        deleteAlert
    } = useResource<OrderType>(endpoint);

    const [chatContext, setChatContext] = useState(undefined);
    useEffect(() => {
        if (data) {
            setChatContext({
                displayAs: `${t('order.show.title')} ${data._id}`,
                sendAs: `${t('order.show.title')} ${data._id}`,
            })
        }
    }, [data, t]);

    useModelUpdate('Order', order_id, fetchResource);
    useModelDelete('Order', order_id, useCallback(() => {
        navigate('/order');
    }, [navigate]));

    const fileInputRef = useRef(null);

    const {
        files,
        isFileUploadPending,
        getRootProps,
        getInputProps,
        isDragActive,
        removeFile,
        setIsFileUploadPending,
        setFiles
    } = useAttachmentUpload();

    const onPressUploadButton = () => fileInputRef.current.click();

    useEffect(() => {
        const upload = async () => {
            if (files.length === 0) {
                return;
            }

            const formData = new FormData();
            Array.from(files).forEach(file => {
                formData.append('files', file);
            });

            setIsFileUploadPending(true);
            const response = await fetch(`${API_URL}/order/${order_id}/attachment`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${authToken}`
                },
                body: formData
            });

            if (response.ok) {
                const data = await response.json();
                forceSetData(data);
            } else if (response.status === 413) {
                pushMessage(t('errors.fileTooLarge'));
            } else if (response.status === 422) {
                pushMessage(t('errors.unsupportedFormat'));
            } else {
                pushMessage(t('common.errorMessage'));
            }

            setIsFileUploadPending(false);
            setFiles([]);
        }
        upload();
    }, [authToken, files, forceSetData, order_id, setFiles, setIsFileUploadPending]);

    return (
        <Dashboard heading={t('order.show.title')} chatContext={chatContext} headerButton={<MarkAsButton orderId={order_id} />}>
            {data ? (
                <>
                    {isFileUploadPending && <div className={"fixed top-0 right-0 w-full h-full z-10 transition-all backdrop-blur bg-opacity-30 bg-blue-50 flex flex-col justify-center items-center gap-2"}>
                        <Spinner size="lg" />
                        <div className={"text-sm"}>{t('common.uploading')}</div>
                    </div> }
                <div className={`border-t border-gray-200`} {...getRootProps()}>
                    <dl className="divide-y divide-gray-200">
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">{t('common.name')}</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{field(data.lead?.name)}</dd>
                        </div>
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">{t('common.note')}</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{field(data.internal_note)}</dd>
                        </div>
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">{t('order.show.items')}</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                {data.items.map((item, index) => (
                                    <div key={index} className={"flex gap-3"}>
                                        <div className={"font-medium"}>{item.product_name}</div>
                                        <div>{item.quantity}{t('order.create.pieces')} x {Price.format(item.price_per_unit, t('currency.symbol'))}</div>
                                    </div>
                                ))}
                            </dd>
                        </div>
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">{t('order.show.billingAndShipping')}</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                <div className={"flex gap-3"}>
                                    <div className={"font-medium"}>{t('common.businessName')}</div>
                                    <div>{field(data.lead?.business_name)}</div>
                                </div>
                                <div className={"flex gap-3"}>
                                    <div className={"font-medium"}>{t('common.billingAddress')}</div>
                                    <div>{field(data.lead?.billing_address)}</div>
                                </div>
                                <div className={"flex gap-3"}>
                                    <div className={"font-medium"}>{t('common.shippingAddress')}</div>
                                    <div>{field(data.lead?.shipping_address)}</div>
                                </div>
                                <div className={"flex gap-3"}>
                                    <div className={"font-medium"}>{t('common.email')}</div>
                                    <div>{field(data.lead?.email)}</div>
                                </div>
                                <div className={"flex gap-3"}>
                                    <div className={"font-medium"}>{t('common.phone')}</div>
                                    <div>{field(data.lead?.phone)}</div>
                                </div>
                                <div className={"flex gap-3"}>
                                    <div className={"font-medium"}>{t('common.cin')}</div>
                                    <div>{field(data.lead?.cin)}</div>
                                </div>
                                <div className={"flex gap-3"}>
                                    <div className={"font-medium"}>{t('common.tin')}</div>
                                    <div>{field(data.lead?.tin)}</div>
                                </div>
                                <div className={"flex gap-3"}>
                                    <div className={"font-medium"}>{t('common.vatin')}</div>
                                    <div>{field(data.lead?.vatin)}</div>
                                </div>
                            </dd>
                        </div>
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">{t('order.show.orderDetails')}</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                <div className={"flex gap-3"}>
                                    <div className={"font-medium"}>{t('order.show.orderNumber')}</div>
                                    <div>{field(data._id)}</div>
                                </div>
                                <div className={"flex gap-3"}>
                                    <div className={"font-medium"}>{t('common.date')}</div>
                                    <div>{field(DateFormatter.fromString(data.created_at))}</div>
                                </div>
                                <div className={"flex gap-3"}>
                                    <div className={"font-medium"}>{t('common.status')}</div>
                                    <div><Badge color={getStatusColor(data.status)}>{getStatusText(data.status)}</Badge>
                                    </div>
                                </div>
                                <div className={"flex gap-3"}>
                                    <div className={"font-medium"}>{t('common.source')}</div>
                                    <div><Badge color={getSourceColor(data.source)}>{getSourceText(data.source)}</Badge>
                                    </div>
                                </div>
                                <div className={"flex gap-3"}>
                                    <div className={"font-medium"}>{t('common.representative')}</div>
                                    <div>{field(data.rep?.name)}</div>
                                </div>
                                <div className={"flex gap-3"}>
                                    <div className={"font-medium"}>{t('common.stock')}</div>
                                    <div>{field(data.stock?.name)}</div>
                                </div>
                            </dd>
                        </div>

                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">{t('common.attachments')}</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                <div className={"flex gap-3 flex-wrap"}>
                                    {
                                        data.attachments?.map((attachment, index) => (
                                            <Attachment attachment={attachment} key={attachment._id} />
                                        ))
                                    }
                                </div>
                                <div
                                    className={`mt-2 flex flex-col justify-center items-center gap-1 w-full h-40 border-2 border-dotted rounded-lg cursor-pointer transition-all duration-200 text-gray-400 hover:text-gray-500 ${isDragActive ? 'bg-blue-100 border-blue-300' : 'border-gray-300 hover:border-gray-400'}`}
                                    onClick={onPressUploadButton}>
                                    <input {...getInputProps()} ref={fileInputRef} className={"hidden"} hidden/>
                                    <div
                                        className={`font-medium text-lg ${isDragActive ? 'animate-bounce text-blue-500' : ''}`}>
                                        <UploadIcon/></div>
                                    <div
                                        className={`font-medium ${isDragActive ? 'text-blue-500' : ''}`}>{t('chat.chatBox.dropzoneActive')}</div>
                                </div>
                            </dd>
                        </div>
                    </dl>
                </div>
                </>) : (
                isPending &&
                <div className={"flex justify-center"}><Spinner size="lg" className={"mt-6 mx-auto"}/></div>
)}
</Dashboard>
);
}

export default OrderShow;