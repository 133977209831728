import React from "react";
import {FormInputContainer} from "../FormStyles";

interface InputFieldProps {
    id: string;
    name: string;
    type: string;
    placeholder: string;
    label: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    value?: string;
    isInvalid?: boolean;
}

const InputField: React.FC<InputFieldProps> = ({id, name, type, placeholder, label, onChange, value, isInvalid}) => {
    return (
        <FormInputContainer label={label} htmlFor={id}>
            <input
                id={id}
                name={name}
                type={type}
                placeholder={placeholder}
                onChange={onChange}
                value={value}
                className={`block w-full rounded-md border-0 py-1.5 ring-1 text-gray-900 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6
                    ${isInvalid ? 'ring-red-400 animationShake' : 'ring-gray-300'}
                `}
            />
        </FormInputContainer>
    );
};

export default InputField;