export class Price {

    static format(cents: number, currency_symbol: string = '€'): string {
        // Convert cents to euros
        let euros = cents / 100;

        // Format the number
        let [wholePart, decimalPart = '00'] = euros.toFixed(2).split('.');

        // Add thousands separator
        wholePart = wholePart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

        // Ensure decimal part has two digits
        decimalPart = decimalPart.padEnd(2, '0');

        // Combine parts and add euro symbol
        return `${wholePart},${decimalPart}${currency_symbol}`;
    }
}
