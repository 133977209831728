import React, {forwardRef, useEffect, useImperativeHandle, useMemo} from 'react';
import styles from './style.module.scss';
import {usePopupMessage} from "../../contexts/PopupMessageContext";

const DURATION: number = 9000;

const PopupMessage = forwardRef((props, ref) => {

    const {messages, push, pop} = usePopupMessage();
    const message = useMemo(() => messages[messages.length - 1], [messages]);

    useImperativeHandle(ref, () => ({
        display(message: string, callback: () => void | Promise<void>) {
            push(message);
        }
    }), [push]);

    useEffect(() => {
        if (!message) return;
        const timeout = setTimeout(() => {
            pop();
        }, DURATION);

        return () => clearTimeout(timeout);
    }, [message, pop]);

    // TODO: change design

    return message && (
        <div className={styles.container}>
            <div className={styles.text}>
                {message.text}
            </div>
            <div className={styles.action}>
                <a onClick={pop}>Zatvoriť</a>
            </div>
        </div>
    );
});


export default PopupMessage;