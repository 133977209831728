import {Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions, Transition} from '@headlessui/react'
import {ChevronUpDownIcon} from '@heroicons/react/20/solid'
import {forwardRef, Fragment, useImperativeHandle, useMemo, useState} from 'react'
import {CountyType} from "../../../types/global";
import useForm from "../../../hooks/UseForm";
import {useDidUpdateEffect} from "../../../hooks/UseDidUpdateEffect";
import {useTranslation} from 'react-i18next';
import {useBusiness} from "../../../contexts/BusinessContext";

// Define the interface for the ref
export interface ComboboxCountyRef {
    setSelectedValue: (value: CountyType | null) => void;
}

// Define the props interface
interface ComboboxCountyProps {
    onChange: (county: CountyType | null) => void;
}

// const Counties = [
//     "Bratislava I" , "Bratislava II" , "Bratislava III" , "Bratislava IV" , "Bratislava V" ,
//     "Malacky" , "Pezinok" , "Senec" ,
//     "Dunajská Streda" , "Galanta" , "Hlohovec" , "Piešťany" , "Senica" , "Skalica" , "Trnava" ,
//     "Bánovce nad Bebravou" , "Ilava" , "Myjava" , "Nové Mesto nad Váhom" , "Partizánske" , "Považská Bystrica" ,
//     "Prievidza" , "Púchov" , "Trenčín" ,
//     "Komárno" , "Levice" , "Nitra" , "Nové Zámky" , "Šaľa" , "Topoľčany" , "Zlaté Moravce" ,
//     "Bytča" , "Čadca" , "Dolný Kubín" , "Kysucké Nové Mesto" , "Liptovský Mikuláš" , "Martin" ,
//     "Námestovo" , "Ružomberok" , "Turčianske Teplice" , "Tvrdošín" , "Žilina" ,
//     "Banská Bystrica" , "Banská Štiavnica" , "Brezno" , "Detva" , "Krupina" , "Lučenec" , "Poltár" ,
//     "Revúca" , "Rimavská Sobota" , "Veľký Krtíš" , "Zvolen" , "Žarnovica" , "Žiar nad Hronom" ,
//     "Bardejov" , "Humenné" , "Kežmarok" , "Levoča" , "Medzilaborce" , "Poprad" , "Prešov" , "Sabinov" ,
//     "Snina" , "Stará Ľubovňa" , "Stropkov" , "Svidník" , "Vranov nad Topľou" ,
//     "Gelnica" , "Košice I" , "Košice II" , "Košice III" , "Košice IV" , "Košice-okolie" , "Michalovce" ,
//     "Rožňava" , "Sobrance" , "Spišská Nová Ves" , "Trebišov"
// ]

const ComboboxCounty = forwardRef<ComboboxCountyRef, ComboboxCountyProps>(({ onChange }, ref) => {
    const { t } = useTranslation();
    const {business} = useBusiness();

    const [selectedCounty, setSelectedCounty] = useState(null);
    const {values, handleChange} = useForm({query: ""})

    useDidUpdateEffect(() => {
        onChange(selectedCounty)
    }, [selectedCounty]);

    useImperativeHandle(ref, () => ({
        setSelectedValue: (value) => {
            setSelectedCounty(value);
        }
    }));

    const counties = useMemo(() => business?.counties || [], [business]);
    const filteredCounties = useMemo(() => {
        return counties.filter((county) => county.toLowerCase().includes(values.query.toLowerCase()))
    }, [counties, values.query]);

    return (
        <div className="min-w-52">
            <Combobox value={selectedCounty} onChange={setSelectedCounty}>
                <div className="relative">
                    <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left border border-gray-300 focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500">
                        <ComboboxInput
                            className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0 placeholder:text-gray-400"
                            displayValue={(county) => county}
                            onChange={handleChange}
                            placeholder={t('lead.create.fields.county')}
                            name={"query"}
                            autoComplete={"off"}
                        />
                        <ComboboxButton className="absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                            />
                        </ComboboxButton>
                    </div>
                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <ComboboxOptions className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-sm z-10">
                            {
                                filteredCounties.map((county) => (
                                    <ComboboxOption
                                        key={county}
                                        className={({ focus }) =>
                                            `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                                focus ? 'bg-indigo-600 text-white' : 'text-gray-900'
                                            }`
                                        }
                                        value={county}
                                    >
                                        {({ selected, focus }) => (
                                            <>
                                                <div
                                                    className={`flex flex-col gap-0`}
                                                >
                                                    <div className={`truncate font-medium`}>{county}</div>
                                                </div>
                                                {selected || (selectedCounty && selectedCounty === county) ? (
                                                    <span
                                                        className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                                            focus ? 'text-white' : 'text-indigo-600'
                                                        }`}
                                                    >
                                                        ✓
                                                    </span>
                                                ) : null}
                                            </>
                                        )}
                                    </ComboboxOption>
                                ))
                            }

                        </ComboboxOptions>
                    </Transition>
                </div>
            </Combobox>
        </div>
    )
})

export default ComboboxCounty;