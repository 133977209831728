import {StockType} from "../../../../types/global";
import {field} from "../../../../utils/FormatingUtils";
import {Link} from 'react-router-dom';
import {useMemo} from "react";

const StockTableRow = ({stock}: { stock: StockType }) => {

    const mappedQuantities = useMemo(() => {
        return stock.quantity.map((quantity, index) => (
            <td key={index} className={"px-2 py-3"}>{quantity.final_quantity}</td>
        ));
    }, [stock])

    return (
        <tr>
            <td className={"font-semibold px-2 py-3"}>{field(stock.name)}</td>
            {mappedQuantities}
            <td className={"px-2 py-3"}>
                <Link
                    to={`/stock/${stock._id}`}
                    type="button"
                    className="bg-grey-200 text-blue-600 font-medium py-1.5 px-3 rounded-md border border-gray-300 no-underline text-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 transition-colors duration-200"
                >
                    {"Detaily"}
                </Link>
            </td>
        </tr>
    )
}

export default StockTableRow;