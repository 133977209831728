import Dashboard from "../../layouts/Dashboard";
import Badge from "../../components/Badge";
import {API_URL} from "../../config";
import {LeadType} from "../../types/global";
import useResource from "../../hooks/UseResource";
import {useNavigate, useParams} from "react-router-dom";
import {Price} from "../../utils/Price";
import {field, getSourceColor, getSourceText} from "../../utils/FormatingUtils";
import {useModelDelete, useModelUpdate} from "../../hooks/UseModelEvents";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Spinner} from "flowbite-react";
import {useTranslation} from 'react-i18next';

const LeadShow = () => {
    const {t} = useTranslation();
    const {lead_id} = useParams<{ lead_id: string }>();
    const navigate = useNavigate();

    const endpoint = useMemo(() => `${API_URL}/lead/${lead_id}`, [lead_id]);
    const {
        data,
        isPending,
        fetchResource,
    } = useResource<LeadType>(endpoint);

    const [chatContext, setChatContext] = useState(undefined);
    useEffect(() => {
        if (data) {
            setChatContext({
                displayAs: `${t('lead.show.title')} ${data.name}`,
                sendAs: `${t('lead.show.title')} ${data._id}`,
            })
        }
    }, [data, t]);

    useModelUpdate('Lead', lead_id, fetchResource);
    useModelDelete('Lead', lead_id, useCallback(() => {
        navigate('/lead');
    }, [navigate]));

    return (
        <Dashboard heading={t('lead.show.title')} chatContext={chatContext}>
            {data ? (
                <div className="border-t border-gray-200">
                    <dl className="divide-y divide-gray-200">
                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">{t('lead.show.contactInfo')}</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                <div className={"flex gap-3"}>
                                    <div className={"font-medium"}>{t('common.name')}</div>
                                    <div>{field(data.name)}</div>
                                </div>
                                <div className={"flex gap-3"}>
                                    <div className={"font-medium"}>{t('common.email')}</div>
                                    <div>{field(data.email)}</div>
                                </div>
                                <div className={"flex gap-3"}>
                                    <div className={"font-medium"}>{t('common.phone')}</div>
                                    <div>{field(data.phone)}</div>
                                </div>
                            </dd>
                        </div>

                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">{t('lead.show.company')}</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                <div className={"flex gap-3"}>
                                    <div className={"font-medium"}>{t('common.businessName')}</div>
                                    <div>{field(data.business_name)}</div>
                                </div>
                                <div className={"flex gap-3"}>
                                    <div className={"font-medium"}>{t('common.billingAddress')}</div>
                                    <div>{field(data.billing_address)}</div>
                                </div>
                                <div className={"flex gap-3"}>
                                    <div className={"font-medium"}>{t('common.shippingAddress')}</div>
                                    <div>{field(data.shipping_address)}</div>
                                </div>
                                <div className={"flex gap-3"}>
                                    <div className={"font-medium"}>{t('common.cin')}</div>
                                    <div>{field(data.cin)}</div>
                                </div>
                                <div className={"flex gap-3"}>
                                    <div className={"font-medium"}>{t('common.tin')}</div>
                                    <div>{field(data.tin)}</div>
                                </div>
                                <div className={"flex gap-3"}>
                                    <div className={"font-medium"}>{t('common.vatin')}</div>
                                    <div>{field(data.vatin)}</div>
                                </div>
                            </dd>
                        </div>

                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">{t('common.details')}</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                <div className={"flex gap-3"}>
                                    <div className={"font-medium"}>{t('common.representative')}</div>
                                    <div>{field(data.rep?.name)}</div>
                                </div>
                                <div className={"flex gap-3"}>
                                    <div className={"font-medium"}>{t('lead.show.fields.county')}</div>
                                    <div>{field(data.county)}</div>
                                </div>
                                <div className={"flex gap-3"}>
                                    <div className={"font-medium"}>{t('lead.show.fields.type')}</div>
                                    <div>{data.source ? <Badge
                                        color={getSourceColor(data.source)}>{getSourceText(data.source)}</Badge> : t('common.noData')}
                                    </div>
                                </div>
                            </dd>
                        </div>

                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">{t('lead.show.specialPrices')}</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                {
                                    (data.custom_prices && data.custom_prices.length) ? data.custom_prices.map((cp) => (
                                        <div className={"flex gap-3"} key={cp.product_name}>
                                            <div className={"font-medium"}>{cp.product_name}</div>
                                            <div>{Price.format(cp.price, t('currency.symbol'))}</div>
                                        </div>
                                    )) : t('common.noData')
                                }
                            </dd>
                        </div>

                        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">{t('common.notes')}</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                {
                                    (data.notes && data.notes.length) ? data.notes.map((note) => (
                                        <div className={"flex gap-3"} key={note._id}>
                                            {field(note.note)}
                                        </div>
                                    )) : t('common.noData')
                                }
                            </dd>
                        </div>
                    </dl>
                </div>
            ) : (
                isPending && <div className={"flex justify-center"}><Spinner size="lg" className={"mt-6 mx-auto"}/></div>
            )}
        </Dashboard>
    );
}

export default LeadShow;