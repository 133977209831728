import {useCallback, useState} from "react";
import {FileRejection, useDropzone} from "react-dropzone";
import {usePopupMessage} from "../contexts/PopupMessageContext";
import {useTranslation} from "react-i18next";

const useAttachmentUpload = () => {
    const [files, setFiles] = useState<File[]>([]);
    const [isFileUploadPending, setIsFileUploadPending] = useState(false);
    const {push: pushMessage} = usePopupMessage();
    const {t} = useTranslation();

    const onDrop = useCallback((acceptedFiles) => {
        setFiles(prevFiles => [...prevFiles, ...acceptedFiles]);
    }, []);

    const onDropRejected = useCallback((fileRejections: FileRejection[]) => {
        console.log("rejected", fileRejections);
        const unsupportedFormatsString = fileRejections.map(({
                                                                 file,
                                                                 errors
                                                             }) => file.name.split('.').pop().toLowerCase()).join('\n');
        pushMessage(t('common.unsupportedFormat') + " " + unsupportedFormatsString);
    }, [pushMessage, t]);

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop,
        onDropRejected,
        multiple: true,
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpg', '.jpeg'],
            'image/gif': ['.gif'],
            'application/pdf': ['.pdf'],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
            'application/msword': ['.doc'],
            'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
            'text/plain': ['.txt'],
            'application/json': ['.json']
        },
        disabled: isFileUploadPending,
        noClick: true,
    });

    const removeFile = useCallback((file) => {
        setFiles(prevFiles => prevFiles.filter(f => f !== file));
    }, []);

    return {
        files,
        isFileUploadPending,
        getRootProps,
        getInputProps,
        isDragActive,
        removeFile,
        setIsFileUploadPending,
        setFiles
    }
}

export default useAttachmentUpload;