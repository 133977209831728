import React, {createContext, useCallback, useContext, useEffect, useState} from 'react';
import {BusinessType, ProductType, RepType} from "../types/global";
import {API_URL} from "../config";
import {useAuth} from "./AuthContext";
import useStatusedState from "../hooks/UseStatusedState";
import {Status} from "../types/enums";


type BusinessContextType = {
    business: BusinessType | null;
    fetchBusiness: () => void;
    products: ProductType[];
    setProducts: React.Dispatch<React.SetStateAction<ProductType[]>>;
    reps: RepType[];
    setReps: React.Dispatch<React.SetStateAction<RepType[]>>;
    productsStatus: Status;
    setProductsStatus: React.Dispatch<React.SetStateAction<Status>>;
    repsStatus: Status;
    setRepsStatus: React.Dispatch<React.SetStateAction<Status>>;
}


// Create a context with default undefined value
const BusinessContext = createContext<BusinessContextType | undefined>(undefined);

// Create a provider component
export const BusinessProvider: React.FC<any> = ({children}) => {
    const [business, setBusiness] = useState<BusinessType>(null);
    const {authToken} = useAuth();

    const {data: products, setData: setProducts, status: productsStatus, setStatus: setProductsStatus} = useStatusedState<ProductType[]>([]);
    const {data: reps, setData: setReps, status: repsStatus, setStatus: setRepsStatus} = useStatusedState<RepType[]>([]);

    const fetchBusiness = useCallback(() => {
        if (authToken) {
            fetch(
                `${API_URL}/business`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${authToken}`,
                    },
                }
            ).then(response => {
                if (response.ok) {
                    response.json().then((data: BusinessType) => {
                        setBusiness(data);
                    });
                }
            })
        }
    }, [authToken]);

    useEffect(() => {
        fetchBusiness();
    }, [fetchBusiness]);

    // TODO: Set up event listener to update business data when the user changes them in the settings (or some other way to handle regions change)

    return (
        <BusinessContext.Provider value={{business, fetchBusiness, products, setProducts, reps, setReps, productsStatus, setProductsStatus, repsStatus, setRepsStatus}}>
            {children}
        </BusinessContext.Provider>
    );
};

// Create a hook to use the BusinessContext, this is a common pattern and helps avoid prop drilling
export const useBusiness = () => {
    const context = useContext(BusinessContext);
    if (context === undefined) {
        throw new Error('useBusiness must be used within an BusinessProvider');
    }
    return context;
};